a {
    color: #61dafb;

    &:hover {
        color: #5f9ea096;
    } 
}

.resume {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1em;
    padding: 1em ;
    margin: 1em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
    box-shadow: 10px 10px 0px 0px rgba(104, 37, 124, 0.5);

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        max-width: 600px;
        margin: 0;
    }
}

.resume-top-left {
    height: min-content;
}

.resume-top {
    padding: .69em 0 1.5em .69em;
    margin-bottom: 1.5em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
    box-shadow: 10px 10px 0px 0px rgba(104, 37, 124, 0.5);
}

.resume-top-right {
    border: 1px solid #ccc;
    border-radius: 0.3em;
    padding: 1em;
    box-shadow: 10px 10px 0px 0px rgba(104, 37, 124, 0.5);
}

.resume-title {
    text-align:left;
    font-weight: bold;
    font-family: 'Amiri Quran', serif;
    font-size: 2.25em;
}
.resume-title-2 {
    text-align:left;
    font-weight: bold;
    font-family: 'Amiri Quran', serif;
    font-size: 2em;
    padding: .5em 0;
    
}

.resume-title-3 {
    font-weight: bold;
    font-family: 'Amiri Quran', serif;
    font-size: 2em;
    margin: 0 0 1em;
}

.resume-subtitle {
    text-align:left;
    padding: .69em 0 0 .69em;
}

.resume-subtitle-2 {
    font-weight: bold;
    font-size: 1.25em;
    text-decoration: underline;
    text-align: left;
}

.resume-contact-info-label {
    padding-right:.5em;
    text-align: end;
}

.resume-details {
    text-align: left;
    padding: .5em 0 .69em 0;
}

.resume-details ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .75em;
    padding: 0 1em;
    margin: 0 0 2em 0;
}

.resume-details-2 {
    padding: 1em 0;
}

.resume-details-2 ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: .75em;
    padding: 0 1em;
    margin: 0 0 10px 0;
    text-align: left;
}

.resume-bottom {
    grid-column-start: 1;
    grid-column-end: 3;
    border: 1px solid #ccc;
    border-radius: 0.3em;
    padding: .69em 0 1.5em .69em;
    box-shadow: 10px 10px 0px 0px rgba(104, 37, 124, 0.5);
}

.work-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    text-align: start;
}

.work-company {
    font-weight: bold;
    font-size: 1.5em;
}
.work-location {
    text-align: end;
    font-size: 1.25em;
}
.work-title {
    font-weight: bold;
    font-size: 1.25em;
}
.work-dates {
    text-align: end;
    font-size: 1.25em;
}

.work-exp {
    padding: 0 5% 0 5%;
    line-height: 1.69em;
}

@media only screen and (max-width: 800px) {
    .resume {
        grid-template-columns: 1fr;
        max-width: 600px;
    }
}