.unicode {
  font-family: 'STIX Two Math'; 
  font-size: 150px;
  font-weight: 600;
  @media only screen and (max-device-width: 969px) {
    font-size: 100px;
  }
  @media only screen and (max-device-width: 666px) {
    font-size: 50px;
  }
}