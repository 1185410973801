button {
  background: transparent;
}

.snake-game {
  justify-content: center;
}

.gamepad {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20vh;
}

.gamepad-button {
  position: relative;
  padding: 0 10px 14px;
  min-width: 3em;
  border: none;
  font-size: 42px;
  margin-top: 6px;
}

.button-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 16px 16px;
  transform: translateY(0);
  text-align: center;
  color: #fff;
  text-shadow: 0 -1px rgb(0 0 0 / .25);
  transition-property: transform;
  transition-duration: .2s;
  -webkit-user-select: none;
  user-select: none;
  
  .gamepad-button:active & {
    transform: translateY(6px);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    background-image: radial-gradient(#c63fcd, #9d369d);
    text-align: center;
    color: #fff;
    box-shadow: (
      inset 0 0 0px 1px rgb(255 255 255 / .2),
      0 1px 2px 1px rgb(255 255 255 / .2),
    );
    transition-property: border-radius, padding, width, transform;
    transition-duration: .2s;
    
    @at-root {
      .gamepad-button:active & {        
        border-radius: 6px;
        padding: 0 2px;
      }
    }
  }
}

.button-bottom {
  position: absolute;
  z-index: -1;
  bottom: 4px;
  left: 4px;
  border-radius: 8px / 16px 16px 8px 8px;
  padding-top: 6px;
  width: calc(100% - 8px);
  height: calc(100% - 10px);
  box-sizing: content-box;
  background-color: rgb(116, 0, 136);
  background-image: (
    radial-gradient(4px 8px at 4px calc(100% - 8px), rgb(255 255 255 / .25), transparent),
    radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgb(255 255 255 / .25), transparent),
    radial-gradient(16px at -4px 0, rgb(255 255 255 / 1), transparent),
    radial-gradient(16px at calc(100% + 4px) 0, rgb(255 255 255 / 1), transparent),
  );
  box-shadow: (
    0px 2px 3px 0px rgb(0 0 0 / .5),
    inset 0px -1px 3px 3px rgb(0 0 0 / .4),
  );
  transition-property: border-radius, padding-top;
  transition-duration: .2s;
  
  .gamepad-button:active & {
    border-radius: 10px 10px 8px 8px / 8px;
    padding-top: 0;
  }
}

.button-base {
  position: absolute;
  z-index: -2;
  top: 4px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100% - 4px);
  background-color: rgb(0 0 0 / .15);
  box-shadow: (
    0 1px 1px 0 rgb(255 255 255 / .75),
    inset 0 2px 2px rgb(0 0 0 / .25),
  );
}

.turn-90 {
  transform: rotate(90deg);
}

.turn-180 {
  transform: rotate(180deg);
}

.turn-270 {
  transform: rotate(270deg);
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}