ul {
  list-style-type: disc;
  margin-top: 2;
  padding-left: 2;
  border-radius: 5px;
}

.app-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.header-text {
  font-size: 2.69em;
  margin-bottom: 0;
  @media only screen and (max-width: 420px) {
    font-size: 2.5em;
  }
}

.header-3-text {
  font-size: 1.5em;
  margin: 0;
  font-style: italic;
}

.App-header {
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  justify-content: space-evenly;
  margin: 0 0 1em 0;
  background-position: center;
  background-size: cover;
}

.wrap-logo {
  position: relative;
}

.logo-layer {
  position: absolute;
}

.App-logo {
  height: 50vmin;
  margin-top: -5vmin;
  margin-bottom: -10vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 16px;
  font-size: calc(10px + 1.33vmin);
}

.button-grp {
  display: grid; 
  width: 69%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;    
  padding: 0.5em;
  margin: .5em;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
}

.button {
  flex-grow: 1;
  border: 2px outset #3abcc0;
  border-radius: 1.1rem;
  background: #3abcc0;
  height: 69px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  font-size: 1.17em;
  text-shadow: 1px -1px 15px rgb(45, 83, 89);
  box-shadow: 0 0 10px #ac9762;

  &:hover {
    background-color: #5f9ea096;
    border: 2px inset #5f9ea096;
  }
}

.selected {
  background-color:#be3ce6;
  border: 2px inset #be3ce6;
  box-shadow: 0 0 10px #fbdc8e;
  
  &:hover {
    background-color: #68257c;
    border: 2px inset #68257c;
  }
}

.start {
  background: #282c34;
  color: white;
  border-radius: 7px;
  border: #282c34 outset 1px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1.2em;
  box-shadow: 0 0 70px #a600ff;
  font-family: "Courier New", Courier, monospace;
  font-style:italic;
  font-weight: 500;
}

.listText {
  text-align: left;
}

.App-footer {
  margin-top: auto;
  display: flex;
  flex-direction: row; 
  font-size: calc(13px);
  color: white;

  @media only screen and (max-device-width: 480px) {
    display: none;
  }
}

