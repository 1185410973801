:root {
  text-align: center;
  background: #282c34;
  color: white;
}

body {
  font-family: 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: 'Fira Code', monospace;
  font-weight: 700;
}

h2 {
  margin: .25em;
}
  
h4 {
  margin: 0;
}
  
.snake-header {
  font-size: 24px;
}

.game-area {
  background: #1e1e1e;
  position: relative;
  width: 620px;
  height: 620px;
  border: 2px solid #3abcc0;
  border-radius: 10px;
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 10px #abbfc0;
  justify-content: center;
  align-content: center;
  z-index: 1;
}

.snake {
  position: absolute;
  width: 1.7%;
  height: 1.7%;
  border: 1px solid rgba(104, 37, 124);
  z-index: 3;
}

.top-left-curve {
  border-top-left-radius: 50%;
}

.top-right-curve {
  border-top-right-radius: 50%;
}

.bottom-left-curve {
  border-bottom-left-radius: 50%;
}

.bottom-right-curve {
  border-bottom-right-radius: 50%;
}

.base-color {
  background-color: rgba(104, 37, 124);
}

.ring-color {
  background-color: rgb(58, 188, 192);
}

.food {
  position: absolute;
  width: 1.7%;
  height: 1.7%;
  background-color: wheat;
  border-radius: 20px;
  z-index: 2;
}


.scoreboard {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1em;
}

.score-title {
  text-align: end;
}

.score-value {
  color: red;
  text-align: start;
}

@media only screen and (max-width: 650px) {
  .snake-game {
    display: contents;
    justify-content: center;
  }

  .game-area {
    position: relative;
    width: 94vw;
    height: 94vw;    
  }

  .snake {
    width: 1.7%;
    height: 1.7%;
  }

  .food {
    border: 1px solid wheat;
  }
}