.bounce {
  animation: bounce 2500ms cubic-bezier(.5,0,.5,1) alternate infinite;
}

.slight-bounce {
  animation: slight-bounce 2500ms cubic-bezier(.5,0,.5,1) alternate infinite;
}

.colorize {
  animation: psychedelic 8s linear infinite;
}

.rock {
  animation: rocking-chair 10000ms cubic-bezier(0.25,0,0.75,1) normal infinite;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@keyframes rocking-chair {
  from {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}


@keyframes bounce {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes slight-bounce {
  from {
    transform: translateY(-3px);
  }
  to {
    transform: translateY(3px);
  }
}

@keyframes psychedelic {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}